import { fGetFunction, fGetLongFunction, fPostFunction } from './firebase'
import { PositionType, PositionTypes } from "app/models/position"
import { IMonitoringReport, MonitoringReport } from 'app/models/monitoring-report'
import { getEpochEndOfTheDay, getEpochStartOfTheDay, getRandomDouble, getRandomInt } from '_metronic/_helpers'
import { NotificationCategory, NotificationSeverity } from 'app/models/notification'
import { times as _times } from "lodash"
import { IMonitoringBradenPlus, IMonitoringBradenQScale, IMonitoringBradenScale, IMonitoringJohnsHopkinsScale, IMonitoringMedicine, IMonitoringMorseScale } from 'app/models/monitoring'

const baseApiUrl = "monitoring"

export function fetchMonitorings(sectorId: string) {
  return fGetFunction(`${baseApiUrl}/sector/${sectorId}/open`)
}

export function addMonitoring(sectorId: string, pacientId: string, deviceId: string, initialPosition: PositionTypes, arrayOfAllowedPositions: Array<string>, pressureInjury: PositionTypes) {
  return fPostFunction(`${baseApiUrl}/add-item`, {
    sectorId          : sectorId,
    deviceId          : deviceId,
    pacientId         : pacientId,
    iniPosition       : initialPosition,
    allowedPositions  : arrayOfAllowedPositions,
    pressureInjury    : pressureInjury
  })
}

export function getMonitoring(id: string) {
  return fGetFunction(`${baseApiUrl}/get-item`, {
    id: id
  })
}

export function getPatientsWarningsEvolution() {
  return fGetFunction(`${baseApiUrl}/patients-warnings-evolution`)
}

export function getPatientsWarningsEvaluation() {
  return fGetFunction(`${baseApiUrl}/patients-warnings-evaluation`)
}

export function getPatientsWarningsLastMedicines() {
  return fGetFunction(`${baseApiUrl}/patients-warnings-last-medicines`)
}

export function replaceDevice(id: string, deviceId: string) {
  return fPostFunction(`${baseApiUrl}/replace-device`, {
    id: id,
    deviceId: deviceId
  })
}

export function changeSector(id: string, sectorId: string) {
  return fPostFunction(`${baseApiUrl}/change-sector`, {
    id: id,
    sectorId: sectorId
  })
}

export function updatePositions(id: string, iniPosition: PositionTypes, allowedPositions: Array<string>) {
  return fPostFunction(`${baseApiUrl}/update-positions`, {
    id: id,
    iniPosition: iniPosition,
    allowedPositions: allowedPositions
  })
}

export function updatePressureInjury(id: string, pressureInjury: any) {
  return fPostFunction(`${baseApiUrl}/update-pressure-injury`, {
    id: id,
    pressureInjury: pressureInjury
  })
}

export function pressureInjuryChange(id: string, nextPosition: PositionTypes) {
  return fPostFunction(`${baseApiUrl}/pressure-injury-change`, {
    id: id,
    nextPosition: nextPosition
  })
}
export function getMedicines(id: string): Promise<Array<IMonitoringMedicine>> {
  return fPostFunction(`${baseApiUrl}/get-medicines`, {
    id: id
  })
}
export function addMedicine(id: string, medicine: IMonitoringMedicine) {
  return fPostFunction(`${baseApiUrl}/add-medicine`, {
    id: id,
    medicine: medicine
  })
}
export function removeMedicine(id: string, medicineId: string) {
  return fPostFunction(`${baseApiUrl}/remove-medicine`, {
    id: id,
    medicineId: medicineId
  })
}

export function updateMorseScale(id: string, morseScale: IMonitoringMorseScale) {
  return fPostFunction(`${baseApiUrl}/update-morse-scale`, {
    id: id,
    morseScale: morseScale
  })
}

export function updateBradenScale(id: string, bradenScale: IMonitoringBradenScale) {
  return fPostFunction(`${baseApiUrl}/update-braden-scale`, {
    id: id,
    scale: bradenScale
  })
}
export function updateBradenQScale(id: string, bradenQScale: IMonitoringBradenQScale) {
  return fPostFunction(`${baseApiUrl}/update-braden-q-scale`, {
    id: id,
    scale: bradenQScale
  })
}
export function updateJohnsHopkinsScale(id: string, johnsHopkinsScale: IMonitoringJohnsHopkinsScale) {
  return fPostFunction(`${baseApiUrl}/update-johns-hopkins-scale`, {
    id: id,
    scale: johnsHopkinsScale
  })
}
export function updateBradenPlus(id: string, bradenPlus: IMonitoringBradenPlus) {
  return fPostFunction(`${baseApiUrl}/update-braden-plus`, {
    id                : id,
    products          : bradenPlus.products,
    definedRisk       : bradenPlus.definedRisk,
    pneumaticMattress : bradenPlus.pneumaticMattress,
    previousInjuries  : bradenPlus.previousInjuries
  })
}

export async function getReports(id: string, startAt?: number, endAt?: number): Promise<MonitoringReport> {
  return fGetFunction(`${baseApiUrl}/reports`, {
    id: id,
    startAt: startAt,
    endAt: endAt
  })
  .then((res: IMonitoringReport) => new MonitoringReport(res))
}
export function getReportsFake(id: string, fromTs?: number, toTs?: number) {
  const _fromTs : number = (fromTs) ? fromTs  : getEpochStartOfTheDay()
  const _toTs   : number = (toTs)   ? toTs    : getEpochEndOfTheDay()

  const sevts = Object.values(NotificationSeverity)
  const poss = Object.values(PositionType)

  return new Promise<MonitoringReport>((acp, rej) => { 
    acp(new MonitoringReport({
      monitoringId  : id,
      ts_start      : _fromTs,
      ts_end        : _toTs,
      outages       : [],
      events        : [],
      dataset       : _times(288, (idx: number) => {
        const cTs: number = _fromTs + (idx * 300000)
        return {
          ts_start    : cTs,
          ts_end      : cTs - 1,
          events      : _times(getRandomInt(1, 5), (id) => {
            return {
              timestamp : cTs + (id * 59999),
              category  : NotificationCategory.POSITION,
              severity  : sevts[getRandomInt(0, sevts.length-1)],
              data      : poss[getRandomInt(0, sevts.length-1)]
            }
          }),
          positions   : new Map(),
          battery     : { avg: (idx % 12 === 0 ? 100 - idx : 51), max: 0, min: 0 },
          heartBeat   : { avg: getRandomInt(80, 110), max: getRandomInt(110, 130), min: getRandomInt(60,80) },
          spo2        : { avg: getRandomInt(97, 99), max: getRandomInt(99, 100), min: getRandomInt(96,97) },
          temperature : { avg: getRandomDouble(36, 38), max: getRandomDouble(38, 40), min: getRandomDouble(35,36) },
        }
      })
    }))
  })
}

export function downloadData(id: string) {
  return fGetLongFunction(`${baseApiUrl}/download-data`, {
    id: id
  })
}

export function dischargeMonitoring(id: string) {
  return fPostFunction(`${baseApiUrl}/discharge`, {
    id: id
  })
}
