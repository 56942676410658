export const PositionType = {
  DDH_DDE: 'DDH_DDE',
  DDE : 'DDE',
  DDE_SED: 'DDE_SED',
  SED : 'SED',

  DDH : 'DDH',
  DLE : 'DLE',
  DLD : 'DLD',
  DV  : 'DV',

  DLE_DV: 'DLE_DV',
  DDH_DLE: 'DDH_DLD',
  DDH_DLD: 'DDH_DLD',
  DLD_DV: 'DLD_DV',

  FALL_RAISED: 'FALL_RAISED', UPSIDE_DOWN: 'UPSIDE_DOWN',
  UNK : 'UNK', INV : 'INV'
} as const
export type PositionTypes = typeof PositionType[keyof typeof PositionType]

export interface PositionMatcher {
  id: PositionTypes
  isMatch: (x: number, y: number) => boolean,
  linked?: Array<PositionTypes>
}
