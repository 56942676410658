import React from "react";
import { injectIntl } from "react-intl"
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { getPercentOfDay } from "_metronic/_helpers";

export interface PIPositionTimerChart {
    DDH: number
    DV: number
    DLE: number
    DLD: number
    SED: number
}

export interface PIPositionTimerChartProps {
    intl: any
    data: any[]
    width?: number
    height?: number
}

function IPositionTimerChart(props: PIPositionTimerChartProps) {
    const { width, height } = props

    let initialPaddings = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }

    const paddings = {
        '350': {
            top: 80,
            left: 70,
            right: 80,
            bottom: 120
        },
        '390': {
            top: 70,
            left: 60,
            right: 80,
            bottom: 110
        },
        '500': {
            top: 60,
            left: 50,
            right: 70,
            bottom: 100
        },
        '630': {
            top: 50,
            left: 30,
            right: 60,
            bottom: 80
        },
        '990': {
            top: 30,
            left: 15,
            right: 40,
            bottom: 60
        },
        '1400': {
            top: 50,
            left: 50,
            right: 70,
            bottom: 90
        },
        '1800': {
            top: 30,
            left: 20,
            right: 50,
            bottom: 80
        }
        ,
        '3000': {
            top: 20,
            left: 10,
            right: 40,
            bottom: 70
        }
    }

    for (const [width, padding] of Object.entries(paddings)) {
        if (window.innerWidth <= Number(width)) {
            initialPaddings = padding;
            break;
        }
    }

    const [options] = React.useState<ApexOptions>({
        chart: {
            id: 'apex',
        },
        title: {
            text: "Relogio de mudança de decubito",
            align: 'center',
        },
        grid: {
            padding: initialPaddings,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "75",
                    background: "transparent",
                    image: 'https://img.elo7.com.br/product/original/3C407C8/relogio-de-parede-mudanca-de-decubito-para-acamados-24cm-cuidadores.jpg',
                    imageWidth: 300,
                    imageHeight: 300,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    imageClipped: false,
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        show: false
                    }
                }
            }
        }
    })

    const [dataSerie, setDataSerie] = React.useState<number>(getPercentOfDay())

    setInterval(() => {
        setDataSerie(getPercentOfDay())
    }, 60000);

    return (
        <Chart
            type="radialBar"
            options={options}
            series={[dataSerie]}
            width={width ?? '100%'}
            height={height ?? '100%'}
        />
    )
}

export default injectIntl((IPositionTimerChart))