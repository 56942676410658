import React from "react";
import { injectIntl } from "react-intl"
import { BaseDonut } from ".";
import { getEpoch } from "_metronic/_helpers";

export interface PacientsStatisticsData {
    // totalPacients: number
    totalMalePacients: number
    totalFemalePacients: number
    totalOtherSexPacients: number
    totalSexNotInformedPacients: number
}

export interface PacientsStatisticsDonutProps {
    intl: any
    data?: PacientsStatisticsData
    width?: number
    height?: number
}

function PacientsStatisticsDonut(props: PacientsStatisticsDonutProps) {
    const { data, width, height } = props
    const [newSignal, setNewSignal] = React.useState<number>(0)

    const onDataSerie = () => {
        if (!data) return null
        return [//data.totalPacients,
                data.totalMalePacients, data.totalFemalePacients, data.totalOtherSexPacients, data.totalSexNotInformedPacients]
    }

    React.useEffect(() => {
        setNewSignal(getEpoch())
    }, [data])

    return (
        <BaseDonut
            width={width ?? '100%'}
            height={height ?? '100%'}
            id='total-pacients-by-sex'
            title="Pacientes por Sexo"
            titleSort="Pacientes por Sexo"
            colors={['#475387', '#DD93AC', '#C2C2F0', '#151617']}
            labels={['Masculino', 'Feminino', 'Outro', 'Não Informado']}
            onDataSerie={onDataSerie}
            newDataSignal={newSignal}
        />
    )
}

export default injectIntl((PacientsStatisticsDonut))
